var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.getAdminUsers.length)?_c('h2',{staticClass:"font-medium text-base mb-16 px-16 sm:px-0"},[_vm._v("Admins")]):_vm._e(),_c('el-table',{staticClass:"mb-32",attrs:{"headings":_vm.tableHeadings,"items":_vm.getAdminUsers},scopedSlots:_vm._u([{key:"enable_order_email",fn:function(ref){
var item = ref.item;
return [_c('el-checkbox',{attrs:{"readonly":""},model:{value:(item.enable_order_email),callback:function ($$v) {_vm.$set(item, "enable_order_email", $$v)},expression:"item.enable_order_email"}})]}},{key:"edit-button",fn:function(ref){
var item = ref.item;
return [_c('el-button',{on:{"click":function($event){return _vm.onEdit(item.id, item.role)}}},[_vm._v("Edit")])]}}])}),(_vm.getAdvertiserUsers.length)?_c('h2',{staticClass:"font-medium text-base mb-16 px-16 sm:px-0"},[_vm._v("Advertisers")]):_vm._e(),_c('el-table',{attrs:{"headings":_vm.tableHeadings,"items":_vm.getAdvertiserUsers},scopedSlots:_vm._u([{key:"enable_order_email",fn:function(ref){
var item = ref.item;
return [_c('el-checkbox',{attrs:{"readonly":""},model:{value:(item.enable_order_email),callback:function ($$v) {_vm.$set(item, "enable_order_email", $$v)},expression:"item.enable_order_email"}})]}},{key:"edit-button",fn:function(ref){
var item = ref.item;
return [_c('el-button',{on:{"click":function($event){return _vm.onEdit(item.id, item.role)}}},[_vm._v("Edit")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }